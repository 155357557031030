import Vue from 'vue'
import axios from 'axios'
import { ToastProgrammatic as Toast } from 'buefy'
import store from '../store'
import router from '../router'
import { CLEAR_USER_INFO } from '../store/mutation-types'
import { delAppFcm } from '../tools/bridge'

/**
 * 判断是测试服还是正式服
 * 测试服基地址：https://ynstaging.look-in.com.tw
 * 正式服基地址：https://apis.look-in.com.tw
 */
const host = window.location.host || ''
let baseURL = 'https://apis.look-in.com.tw' // 默認正式服域名
const NETWORK_ERROR = '網絡異常，請稍後重試'

if (host.includes('staging')) {
  baseURL = 'https://ynstaging.look-in.com.tw/apis'
} else if (host.includes('localhost')) {
  baseURL = 'https://ynstaging.look-in.com.tw/apis'
} else {
  baseURL = 'https://apis.look-in.com.tw'
}

// 清除用户信息
function clearUserInfo() {
  window.localStorage.removeItem('auth-token')
  const userId = Vue.$cookies.get('user_id')
  Vue.$cookies.remove('user_id')
  Vue.$cookies.remove('user')
  store.commit(CLEAR_USER_INFO)
  delAppFcm(userId)
}

function handleError(response) {
  const err = text => {
    console.log(response?.data?.message ?? text)
    Toast.open({
      duration: 2000,
      type: 'is-danger',
      message: response?.data?.message ?? text
    })
  }
  if (!response.data) {
    err('請求超時，服務器無響應！')
    return
  }
  // code是401，状态码不是情况处理
  if (response.data.code === 401) {
    err('登錄狀態已過期，需要重新登錄')
    clearUserInfo()
    router.push('/')
    // 弹出登录页
    store.dispatch('setLoginDrawer', true)
    return
  }
  const handleMap = {
    404: () => err('服務器資源不存在'),
    500: () => err('服務器內部錯誤'),
    403: () => err('沒有權限訪問該資源'),
    401: () => {
      err('登錄狀態已過期，需要重新登錄')
      clearUserInfo()
      // TODO 跳转实际登录页
      router.push('/')
      // 弹出登录页
      store.dispatch('setLoginDrawer', true)
    }
  }
  handleMap[response.status] ? handleMap[response.status]() : err('未知错误！')
}

const service = axios.create({
  baseURL,
  timeout: 50000
})

// 請求攔截
service.interceptors.request.use(
  req => {
    const token = window.localStorage.getItem('auth-token')
    // 有就携带token
    if (token) {
      req.headers.Authorization = token
    }
    return req
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    // if (response.data.code !== 200) {
    //   handleError(response)
    //   return Promise.reject(response.data)
    // }
    // token过期 其他的不做处理，兼容以前的写法
    if (response.data.code === 401) {
      handleError(response)
      return Promise.reject(response.data)
    }
    return response.data
  },
  error => {
    Toast.open({
      duration: 2000,
      type: 'is-danger',
      message: NETWORK_ERROR
    })
    return Promise.reject(error)
  }
)

export default service
export { baseURL }
